import { Map, Marker } from "pigeon-maps";
import { Spot, SpotCoords } from "../../store/types";
import { useEffect, useState } from "react";

type SpotMapProps = {
  currentSpot: Spot | null;
  mapHeight: number;
  spots: Spot[];
  handleMarkerClicked: (spot: Spot) => void;
  handleTempMarkerClicked: (coords: SpotCoords) => void;
};

export default function SpotMap({
  currentSpot,
  mapHeight,
  spots,
  handleMarkerClicked,
  handleTempMarkerClicked,
}: SpotMapProps) {
  const [zoom, setZoom] = useState<[number, number]>([48.866667, 2.333333]);
  const [zoomValue, setZoomValue] = useState(11);
  const [tempMarker, setTempMarker] = useState<SpotCoords | null>(null);

  useEffect(() => {
    if (tempMarker !== null) {
      setZoomValue((zoomValue) => 17);
      setZoom((zoom) => [tempMarker.lat, tempMarker.lon]);
    }
  }, [tempMarker]);

  useEffect(() => {
    if (currentSpot !== null) {
      setZoomValue((zoomValue) => 15);
      setZoom((zoom) => [currentSpot.lat, currentSpot.lon]);
    }
  }, [currentSpot]);

  if (spots === undefined || spots.length === 0) return null;

  function resolveColor(spot: Spot) {
    if (spot.done !== undefined && spot.done === true) {
      return "gray";
    }
    if (spot.dead !== undefined && spot.dead === true) {
      return "#ed6767";
    }
    if (spot.fav !== undefined && spot.fav === true) {
      return "#61c36d";
    }
    return "#86a9d5";
  }

  function handleCreatePoint(o: { latLng: [number, number] }) {
    const marker = {
      lat: o.latLng[0],
      lon: o.latLng[1],
    };
    setTempMarker((tempMarker) => marker);
  }

  function handleCreateTempMarker() {
    if (tempMarker === null) return;
    handleTempMarkerClicked(tempMarker);
  }

  return (
    <Map
      zoom={zoomValue}
      height={mapHeight}
      center={zoom}
      defaultCenter={zoom}
      defaultZoom={11}
      onClick={(o) => handleCreatePoint(o)}
    >
      {spots
        .filter((spot) => spot.hidden === undefined || spot.hidden === false)
        .map((spot, index) => {
          return (
            <Marker
              hover={zoom[0] === spot.lat && zoom[1] === spot.lon}
              height={zoom[0] === spot.lat && zoom[1] === spot.lon ? 50 : 30}
              color={resolveColor(spot)}
              onClick={() => handleMarkerClicked(spot)}
              key={index}
              width={50}
              anchor={[spot.lat, spot.lon]}
            />
          );
        })}
      {tempMarker !== null && (
        <Marker
          color={"#efc258"}
          onClick={handleCreateTempMarker}
          anchor={[tempMarker.lat, tempMarker.lon]}
        />
      )}
    </Map>
  );
}
