import { useState } from "react";
import styles from "./AppTabs.module.css";

export type AppTab = {
  title: string;
};

type AppTabsProps = {
  tabs: AppTab[];
  handleTabSelected: (tab: AppTab) => void;
};

export default function AppTabs({ tabs, handleTabSelected }: AppTabsProps) {
  const [selectedTab, setSelectedTab] = useState<AppTab | null>(null);

  function handleSelect(tab: AppTab) {
    setSelectedTab((selectedTab) => tab);
    handleTabSelected(tab);
  }
  return (
    <ul className={styles.AppTabs}>
      {tabs.map((t, i) => {
        return (
          <li
            className={`${
              selectedTab !== null && selectedTab.title === t.title
                ? styles.selected
                : null
            }`}
            onClick={() => handleSelect(t)}
            key={i}
          >
            {t.title}
          </li>
        );
      })}
    </ul>
  );
}
