import { createContext, useContext, useState } from "react";

export type AppNotification = {
  title: string;
  message: string;
};

type AppNotificationContext = {
  notification?: AppNotification;
};

const AppNotificationContext = createContext<any>({});

export const AppNotificationProvider: React.FC<any> = ({ children }) => {
  const [notification, setNotification] = useState<AppNotification | null>(
    null
  );

  const value = {
    notification,
    setNotification,
  };

  return (
    <AppNotificationContext.Provider value={value}>
      {children}
    </AppNotificationContext.Provider>
  );
};

export function useNotification(): {
  notification: AppNotification;
  setNotification: (notification: AppNotification) => AppNotification;
} {
  const { notification, setNotification } = useContext(AppNotificationContext);

  return {
    notification,
    setNotification,
  };
}
