import { BasicStates } from "../../store/types";
import styles from "./AppTag.module.css";

type AppTagProps = {
  type?: BasicStates;
  title: string;
};

export default function AppTag({ title, type }: AppTagProps) {
  return (
    <label
      className={`${styles.AppTag} ${
        type !== undefined ? styles[type] : styles["Primary"]
      }`}
    >
      {title}
    </label>
  );
}
