"use client";

import { useEffect, useState } from "react";
import styles from "./notification.module.css";
import { AppNotification, useNotification } from "../../store/notifications";

export default function Notification() {
  const [notif, setNotif] = useState<AppNotification | null>(null);

  const { notification } = useNotification();

  useEffect(() => {
    if (notif !== null) {
      setTimeout(() => {
        setNotif(null);
      }, 5000);
    }
  }, [notif]);

  useEffect(() => {
    setNotif(notification);
  }, [notification]);

  return (
    <>
      {notif ? (
        <div className={styles.notification}>
          <div className={styles.notificationHeader}>{notification.title}</div>
          <div className={styles.notificationBody}>{notification.message}</div>
        </div>
      ) : null}
    </>
  );
}
