import AppButton, { AppButtonType } from "../AppButton/AppButton";
import AppModale, { ModalContent, ModalFooter, ModalTitle } from "./AppModale";

type AppModaleConfirmProps = {
  isOpen: boolean;
  text: string;
  handleResponse: (confirm: boolean) => void;
};

export default function AppModaleConfirm({
  isOpen,
  text,
  handleResponse,
}: AppModaleConfirmProps) {
  return (
    <AppModale fireCloseEvent={() => {}} isOpen={isOpen} fullSize={true}>
      <ModalTitle>Confirmation</ModalTitle>
      <ModalContent>{text}</ModalContent>
      <ModalFooter>
        <AppButton
          buttonType={AppButtonType.Secondary}
          onClick={() => handleResponse(false)}
        >
          Annuler
        </AppButton>
        <AppButton
          buttonType={AppButtonType.Primary}
          onClick={() => handleResponse(true)}
        >
          Confirmer
        </AppButton>
      </ModalFooter>
    </AppModale>
  );
}
