export enum SpotRate {
  Nul = "nul",
  Bof = "bof",
  Bien = "bien",
}

export type SpotCoords = {
  lat: number;
  lon: number;
};

export type PartialSpot = {
  title: string;
  lat: number;
  lon: number;
};

export type Spot = {
  id: number;
  title: string;
  regionId: number;
  lat: number;
  lon: number;
  fav?: boolean;
  dead?: boolean;
  done?: boolean;
  rate?: SpotRate;
  hidden?: boolean;
};

export type StorageMaps = {
  title: string;
  content: Spot[];
};

export type BasicStates =
  | "Primary"
  | "Success"
  | "Warning"
  | "Error"
  | "Default";
