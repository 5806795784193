import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStore } from "../../store/store";
import styles from "./Navbar.module.css";
import { faGlobe, faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import AppButton from "../AppButton/AppButton";
import { useFilePicker } from "use-file-picker";
import { Utils } from "../../utils/utils";
import { useNotification } from "../../store/notifications";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "../IconButton/IconButton";

export function Navbar() {
  const navigate = useNavigate();
  const { localeMaps, loadLocaleMaps } = useStore();
  const { setNotification } = useNotification();
  const [navOpened, setNavOpened] = useState(true);
  const { openFilePicker, filesContent, loading, errors, plainFiles, clear } =
    useFilePicker({
      multiple: true,
      readAs: "Text",
      accept: [".json"],
      onFilesSelected: async ({ plainFiles, filesContent, errors }) => {
        // this callback is always called, even if there are errors
        const encodedContent = filesContent[0].content;
        const content = JSON.parse(encodedContent);
        const res = await Utils.resolveSpots(content);
        if (res === null) {
          setNotification({
            message: "Le fichier n'est pas compatible.",
            title: "Erreur",
          });
        } else {
          setNotification({
            message: "La map a été sauvegardée avec succès.",
            title: "Succès",
          });
          loadLocaleMaps();
        }
      },
      onFilesRejected: ({ errors }) => {
        setNotification({
          message: "Le fichier n'est pas un json.",
          title: "Erreur",
        });
      },
    });

  useEffect(() => {
    loadLocaleMaps();
    resolveLastOpenedMap();
  }, []);

  function resolveLastOpenedMap() {
    const lastOpenedMap = Utils.getLastOpenedMap();
    if (lastOpenedMap !== null) {
      handleSelectMap(lastOpenedMap.title);
    }
  }

  function handleSelectMap(title: string) {
    setNavOpened((navOpened) => false);
    navigate(`appMap/${title}`);
  }

  if (loading) {
    return null;
  }

  return (
    <nav
      className={`${styles.Nav} ${
        navOpened ? styles.NavOpened : styles.NavClosed
      }`}
    >
      <div className={styles.NavContent}>
        <div className={styles.NavHeader}>
          <FontAwesomeIcon icon={faGlobe} />
          <h2>Maps</h2>
        </div>
        <hr />
        <ul>
          {localeMaps.length === 0 ? (
            <li className={styles.Nomap}>Aucune map enregistrée.</li>
          ) : (
            localeMaps.map(({ title }, index) => {
              return (
                <li onClick={() => handleSelectMap(title)} key={index}>
                  {title}
                </li>
              );
            })
          )}
        </ul>
        <hr />
        <div className={styles.NavBottom}>
          <AppButton onClick={openFilePicker}>Charger une map</AppButton>
        </div>
      </div>
      <div className={styles.NavButtons}>
        <IconButton
          handleClick={() => setNavOpened((navOpened) => !navOpened)}
          icon={navOpened ? "close" : "bars"}
        />
      </div>
    </nav>
  );
}
