import { useParams } from "react-router-dom";
import Section from "../../components/Section/Section";
import { Utils } from "../../utils/utils";
import SpotMap from "../../components/SpotMap/SpotMap";
import { useEffect, useState } from "react";
import styles from "./AppMap.module.css";
import { Spot, SpotCoords, StorageMaps } from "../../store/types";
import IconButton from "../../components/IconButton/IconButton";
import AppMapTabbedList from "./AppMapTabbedList";
import AppMapSpotDetails from "./AppMapSpotDetails";
import AppModaleCreateSpot from "../../components/AppModale/AppModaleCreateSpot";
import AppModaleConfirm from "../../components/AppModale/AppModaleConfirm";
import { useNotification } from "../../store/notifications";

type MenuMode = "list" | "favs";

export default function AppMap() {
  const { setNotification } = useNotification();

  useEffect(() => {
    function updateMapHeight() {
      const windowHeight = window.innerHeight;
      const height = windowHeight - 4;
      setMapHeight((mapHeight) => height);
    }
    updateMapHeight();
    window.addEventListener("resize", updateMapHeight);
    return () => {
      window.removeEventListener("resize", updateMapHeight);
    };
  }, []);

  const [mapHeight, setMapHeight] = useState(0);
  const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null);
  const [mapMenuOpened, setMapMenuOpened] = useState(false);
  const [currentMap, setCurrentMap] = useState<StorageMaps | null>(null);
  const [newPoint, setNewPoint] = useState<SpotCoords | null>(null);
  const [newSpotModaleOpen, setNewSpotModaleOpen] = useState<boolean>(false);
  const [confirmDeleteSpotModaleOpen, setConfirmDeleteSpotModaleOpen] =
    useState<boolean>(false);

  const params = useParams();

  function loadMap() {
    if (currentMap !== null) {
      setCurrentMap((currentMap) => null);
    }
    const mapTitle = params.title;
    if (mapTitle !== undefined) {
      const map = Utils.resolveMap(mapTitle);
      if (map !== null) {
        Utils.setLastOpenedMap(map);
        setCurrentMap((currentMap) => map);
      }
    }
  }

  function handleTempMarkerClicked(coords: SpotCoords) {
    setNewPoint((newPoint) => coords);
    setNewSpotModaleOpen((newSpotModaleOpen) => true);
  }

  function handleDeleteSpot(confirm: boolean) {
    if (selectedSpot === null) {
      setConfirmDeleteSpotModaleOpen((confirmDeleteSpotModaleOpen) => false);
      return;
    }
    if (confirm === false) {
      setConfirmDeleteSpotModaleOpen((confirmDeleteSpotModaleOpen) => false);
    } else {
      Utils.deleteSpot(selectedSpot);
      setConfirmDeleteSpotModaleOpen((confirmDeleteSpotModaleOpen) => false);
      setSelectedSpot((selectedSpot) => null);
      setNotification({
        title: "Succès",
        message: "Spot supprimé avec succès.",
      });
      loadMap();
    }
  }

  useEffect(() => {
    loadMap();
  }, []);

  return (
    <Section noPadding={true}>
      {currentMap !== null && (
        <>
          <div className={styles.MapActions}>
            <IconButton
              handleClick={() => {
                setMapMenuOpened((mapMenuOpened) => !mapMenuOpened);
              }}
              icon="list"
            />
          </div>
          <AppMapTabbedList
            handleCloseMenu={() => setMapMenuOpened((mapMenuOpened) => false)}
            open={mapMenuOpened}
            map={currentMap}
            handleSpotSelected={(spot) => {
              setMapMenuOpened((mapMenuOpened) => false);
              setSelectedSpot((selectedSpot) => spot);
            }}
          />
          <SpotMap
            handleMarkerClicked={(spot) => {
              setSelectedSpot(spot);
            }}
            handleTempMarkerClicked={handleTempMarkerClicked}
            mapHeight={mapHeight}
            currentSpot={selectedSpot}
            spots={currentMap.content}
          />
          {selectedSpot !== null && (
            <AppMapSpotDetails
              handleRequestDeletePoint={() =>
                setConfirmDeleteSpotModaleOpen(
                  (confirmDeleteSpotModaleOpen) => true
                )
              }
              map={currentMap}
              spot={selectedSpot}
              handleClose={() => setSelectedSpot((selectedSpot) => null)}
              handleLoadMap={() => loadMap()}
            />
          )}
        </>
      )}
      {newPoint !== null && (
        <AppModaleCreateSpot
          spotCreatedEvent={() => {
            setNewPoint((newPoint) => null);
            setNewSpotModaleOpen((newSpotModaleOpen) => false);
            loadMap();
          }}
          closeEvent={() => {
            setNewPoint((newPoint) => null);
            setNewSpotModaleOpen((newSpotModaleOpen) => false);
          }}
          coords={newPoint}
          isOpen={newSpotModaleOpen}
        />
      )}
      {selectedSpot !== null && (
        <AppModaleConfirm
          handleResponse={handleDeleteSpot}
          text="Souhaitez-vous supprimer ce spot ?"
          isOpen={confirmDeleteSpotModaleOpen}
        />
      )}
    </Section>
  );
}
