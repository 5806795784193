import styles from "./Section.module.css";

type SectionProps = {
  noPadding?: boolean;
  children: React.ReactNode;
};

export function TitleSection({ title }: { title: string }) {
  return <h2 className={styles.TitleSection}>{title}</h2>;
}

export default function Section({ children, noPadding }: SectionProps) {
  return (
    <section
      className={`${styles.Section} ${
        noPadding !== undefined && noPadding === true
          ? styles["NoPadding"]
          : null
      }`}
    >
      {children}
    </section>
  );
}
