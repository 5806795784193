import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./globals.css";
import styles from "./App.module.css";
import { AppProvider } from "./store/store";
import { Navbar } from "./components/Navbar/Navbar";
import { AppNotificationProvider } from "./store/notifications";
import Notification from "./components/Notification/notification";
import Welcome from "./pages/Welcome/Welcome";
import AppMap from "./pages/AppMap/AppMap";

function App() {
  return (
    <AppNotificationProvider>
      <AppProvider>
        <main className={styles.App}>
          <Notification />
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Welcome />}>
                <Route index element={<Welcome />} />
              </Route>
              <Route path="/welcome" element={<Welcome />}>
                <Route element={<Welcome />} />
              </Route>
              <Route path="/appMap/:title" element={<AppMap />}>
                <Route element={<AppMap />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </main>
      </AppProvider>
    </AppNotificationProvider>
  );
}

export default App;
