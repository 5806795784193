import { useState } from "react";
import { PartialSpot, Spot, SpotCoords } from "../../store/types";
import AppButton, { AppButtonType } from "../AppButton/AppButton";
import AppModale, { ModalContent, ModalFooter, ModalTitle } from "./AppModale";
import styles from "./AppModale.module.css";
import { Utils } from "../../utils/utils";
import { useNotification } from "../../store/notifications";

type AppModaleCreateSpotProps = {
  isOpen: boolean;
  coords: SpotCoords;
  closeEvent: () => void;
  spotCreatedEvent: () => void;
};

export default function AppModaleCreateSpot({
  coords,
  isOpen,
  closeEvent,
  spotCreatedEvent,
}: AppModaleCreateSpotProps) {
  const { setNotification } = useNotification();

  const [newSpotName, setNewSpotName] = useState<string>("");

  function handleCloseEvent() {
    closeEvent();
  }

  async function handleCreateSpot() {
    try {
      await Utils.createSpot({
        title: newSpotName,
        lat: coords.lat,
        lon: coords.lon,
      });
      setNotification({
        title: "Succès",
        message: "Le spot a été ajouté avec succès !",
      });
      spotCreatedEvent();
    } catch (e) {
      setNotification({
        title: "Erreur",
        message: "Erreur lors de la création du spot.",
      });
    }
  }

  return (
    <AppModale
      fireCloseEvent={() => handleCloseEvent()}
      isOpen={isOpen}
      fullSize={true}
    >
      <ModalTitle>Ajouter un nouveau spot</ModalTitle>
      <ModalContent>
        <ul className={styles.ModalForm}>
          <li>
            <label htmlFor="title">Nom du spot:</label>
            <input
              type="text"
              id="title"
              name="title"
              value={newSpotName}
              onChange={(e) => setNewSpotName((newSpotName) => e.target.value)}
            />
          </li>
          <li>
            <p>
              Ce spot sera ajouté aux coordonnées suivantes:
              <br />
              {coords.lat},{coords.lon}{" "}
            </p>
          </li>
        </ul>
      </ModalContent>
      <ModalFooter>
        <AppButton
          buttonType={AppButtonType.Secondary}
          onClick={handleCloseEvent}
        >
          Annuler
        </AppButton>
        {newSpotName.length > 0 && (
          <AppButton
            buttonType={AppButtonType.Primary}
            onClick={handleCreateSpot}
          >
            Confirmer
          </AppButton>
        )}
      </ModalFooter>
    </AppModale>
  );
}
