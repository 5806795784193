import { useContext, createContext, useState } from "react";
import { StorageMaps } from "./types";
import { KEY_MAPS } from "../utils/utils";

const AppContext = createContext<any>({});

export const AppProvider: React.FC<any> = ({ children }) => {
  const [localeMaps, setLocaleMaps] = useState<StorageMaps[]>([]);

  function loadLocaleMaps() {
    let savedMaps: StorageMaps[] = [];
    const storageKeyMaps = localStorage.getItem(KEY_MAPS);
    if (storageKeyMaps !== null) {
      const parsedStorageKeyMaps: StorageMaps[] = JSON.parse(storageKeyMaps);
      savedMaps = parsedStorageKeyMaps;
    }
    setLocaleMaps((localeMaps) => savedMaps);
  }

  const value = {
    localeMaps,
    loadLocaleMaps,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useStore(): {
  localeMaps: StorageMaps[];
  loadLocaleMaps: () => void;
} {
  const { localeMaps, loadLocaleMaps } = useContext(AppContext);

  return {
    localeMaps,
    loadLocaleMaps,
  };
}
