import styles from "./AppButton.module.css";

export enum AppButtonStyle {
  Default = "Default",
  Dashed = "Dashed",
}

export enum AppButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Danger = "Danger",
}

export enum AppButtonEffect {
  Flash = "Flash",
}

type AppButtonProps = {
  children: React.ReactNode;
  buttonStyle?: AppButtonStyle;
  buttonType?: AppButtonType;
  buttonEffect?: AppButtonEffect;
  onClick: any;
};

export default function AppButton({
  children,
  buttonType,
  buttonStyle,
  buttonEffect,
  onClick,
}: AppButtonProps) {
  return (
    <button
      data-content={children}
      onClick={onClick}
      className={`
                ${styles.AppButtons} 
                ${
                  buttonEffect === null || buttonEffect === undefined
                    ? ""
                    : styles[buttonEffect]
                }
                ${
                  buttonType === null || buttonType === undefined
                    ? styles["Primary"]
                    : styles[buttonType]
                } 
                ${
                  buttonStyle === null || buttonStyle === undefined
                    ? styles[AppButtonStyle.Default]
                    : styles[buttonStyle]
                }
            `}
    >
      {children}
    </button>
  );
}
