import Section, { TitleSection } from "../../components/Section/Section";
import { Spot, StorageMaps } from "../../store/types";
import AppSpotList from "./AppSpotList";
import styles from "./AppMap.module.css";
import AppButton from "../../components/AppButton/AppButton";
import AppTabs, { AppTab } from "../../components/AppTabs/AppTabs";
import { useEffect, useState } from "react";

type AppMapTabbedListProps = {
  map: StorageMaps;
  open: boolean;
  handleCloseMenu: () => void;
  handleSpotSelected: (spot: Spot) => void;
};

export default function AppMapTabbedList({
  open,
  map,
  handleCloseMenu,
  handleSpotSelected,
}: AppMapTabbedListProps) {
  const [filteredSpots, setFilteredSpots] = useState<Spot[] | null>(null);
  const [localeTabs, setLocaleTabs] = useState<AppTab[] | null>(null);

  function resolveSpots(tab: AppTab) {
    let filtered: Spot[] = [];
    if (tab.title === "A visiter") {
      filtered = map.content.filter(
        (spot) =>
          spot.fav === true && (spot.done === undefined || spot.done === false)
      );
      console.log(filtered);
    }
    if (tab.title === "Dead Spots") {
      filtered = map.content.filter((spot) => spot.dead === true);
    }
    if (tab.title === "Visités") {
      filtered = map.content.filter((spot) => spot.done === true);
    }
    setFilteredSpots((filteredSpots) => filtered);
  }

  useEffect(() => {
    if (open === true) {
      const tabs = loadTabs();
      setLocaleTabs((loadTabs) => tabs);
    }
  }, [open]);

  function loadTabs(): AppTab[] {
    return [
      { title: "A visiter" },
      { title: "Visités" },
      { title: "Dead Spots" },
    ];
  }

  function handleLocaleCloseMenu() {
    setLocaleTabs((loadTabs) => null);
    setFilteredSpots((filteredSpots) => []);
    handleCloseMenu();
  }

  return (
    <div className={`${styles.MapMenu} ${open ? styles.opened : null}`}>
      <Section>
        {localeTabs !== null && (
          <AppTabs
            handleTabSelected={(tab) => resolveSpots(tab)}
            tabs={localeTabs}
          />
        )}
        <br />
        {filteredSpots !== null && filteredSpots.length > 0 ? (
          <AppSpotList
            handleClick={(spot) => {
              handleSpotSelected(spot);
              handleLocaleCloseMenu();
            }}
            list={filteredSpots}
          />
        ) : (
          <em>Rien trouvé.</em>
        )}
      </Section>
      <div className={styles.MapMenuCloseButton}>
        <AppButton onClick={() => handleLocaleCloseMenu()}>Fermer</AppButton>
      </div>
    </div>
  );
}
