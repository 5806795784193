import AppButton, { AppButtonType } from "../../components/AppButton/AppButton";
import { AppOsefBar } from "../../components/AppOsef/AppOsef";
import AppTag from "../../components/AppTag/AppTag";
import IconButton from "../../components/IconButton/IconButton";
import { BasicStates, Spot, SpotRate, StorageMaps } from "../../store/types";
import { Utils } from "../../utils/utils";
import styles from "./AppMap.module.css";
import { faSmile } from "@fortawesome/free-solid-svg-icons";

type AppMapSpotDetailsProps = {
  map: StorageMaps;
  spot: Spot;
  handleClose: () => void;
  handleRequestDeletePoint: () => void;
  handleLoadMap: () => void;
};

export default function AppMapSpotDetails({
  map,
  spot,
  handleLoadMap,
  handleRequestDeletePoint,
  handleClose,
}: AppMapSpotDetailsProps) {
  function handleLoadSpot(spot: Spot) {
    window.location.href = `http://maps.apple.com/?q=${spot.lat},${spot.lon}`;
  }

  function handleAddFav(spot: Spot, action: boolean) {
    if (map) {
      const title = map.title;
      spot.fav = action;
      Utils.updateSpot(title, spot);
      handleLoadMap();
    }
  }

  function handleAddDead(spot: Spot, action: boolean) {
    if (map) {
      const title = map.title;
      spot.dead = action;
      Utils.updateSpot(title, spot);
      handleLoadMap();
    }
  }

  function handleAddDone(spot: Spot, action: boolean) {
    if (map) {
      const title = map.title;
      spot.done = action;
      Utils.updateSpot(title, spot);
      handleLoadMap();
    }
  }

  function handleAddRate(spot: Spot, rate: SpotRate) {
    if (map) {
      const title = map.title;
      spot.rate = rate;
      Utils.updateSpot(title, spot);
      handleLoadMap();
    }
  }

  function resolveRateChoices(spot: Spot) {
    function resolveEmoji(v: string) {
      let iconName: "sad" | "smile" | "pf" | null =
        v === SpotRate.Nul
          ? "sad"
          : v === SpotRate.Bien
          ? "smile"
          : v === SpotRate.Bof
          ? "pf"
          : null;
      if (iconName === null) return null;
      const toHighlight = spot.rate !== undefined && spot.rate === v;
      const mode = function (): BasicStates | undefined {
        if (iconName === "pf") {
          return "Warning";
        }
        if (iconName === "smile") {
          return "Success";
        }
        if (iconName === "sad") {
          return "Error";
        }
        return undefined;
      };
      return (
        <IconButton
          type="Default"
          icon={iconName}
          highlighted={toHighlight}
          highlightMode={toHighlight ? mode() : undefined}
          handleClick={() => {
            handleAddRate(spot, v as SpotRate);
          }}
        />
      );
    }
    return (
      <ul className={styles.RateList}>
        {Object.entries(SpotRate)
          .reverse()
          .map(([k, v], i) => {
            return <li key={i}>{resolveEmoji(v)}</li>;
          })}
      </ul>
    );
  }
  return (
    <div className={styles.SpotInformations}>
      <div className={styles.SpotInformationsTitle}>
        <IconButton icon="close" type="Default" handleClick={handleClose} />
        <h3>{spot.title}</h3>
      </div>
      <div className={styles.SpotInformationsContent}>
        {spot.dead !== undefined && spot.dead === true ? (
          <AppTag type="Error" title="Deadspot" />
        ) : null}
        {spot.done !== undefined && spot.done === true ? (
          <AppTag type="Default" title="Déjà fait" />
        ) : null}
        {spot.fav !== undefined && spot.fav === true ? (
          <AppTag type="Success" title="Favori" />
        ) : null}
      </div>
      <label>Lat: {spot.lat}</label>
      <br />
      <label>Lon: {spot.lon}</label>
      <hr />
      <div className={styles.SpotInformationsActions}>
        <AppButton onClick={() => handleLoadSpot(spot)}>
          Voir le point
        </AppButton>
        <AppButton
          buttonType={AppButtonType.Danger}
          onClick={handleRequestDeletePoint}
        >
          Supprimer le point
        </AppButton>
        <hr className={styles.MobileSeparator} />
        <IconButton
          type="Default"
          handleClick={() =>
            handleAddFav(spot, spot.fav === undefined || spot.fav === false)
          }
          icon={
            spot.fav === undefined || spot.fav === false ? "starStroke" : "star"
          }
          hoverTag="Fav"
          highlighted={spot.fav !== undefined && spot.fav === true}
          highlightMode={
            spot.fav !== undefined && spot.fav === true ? "Warning" : undefined
          }
        />
        <IconButton
          type="Default"
          hoverTag="Deadspot"
          handleClick={() =>
            handleAddDead(spot, spot.dead === undefined || spot.dead === false)
          }
          icon={"skull"}
          highlighted={spot.dead !== undefined && spot.dead === true}
          highlightMode={
            spot.dead !== undefined && spot.dead === true ? "Error" : undefined
          }
        />
        <IconButton
          type="Default"
          handleClick={() =>
            handleAddDone(spot, spot.done === undefined || spot.done === false)
          }
          icon={
            spot.done === undefined || spot.done === false ? "eye" : "eyeClosed"
          }
          hoverTag="Fait"
          highlighted={spot.done !== undefined && spot.done === true}
          highlightMode={
            spot.done !== undefined && spot.done === true
              ? "Default"
              : undefined
          }
        />
        {spot.done !== undefined && spot.done === true && (
          <>
            <AppOsefBar />
            {resolveRateChoices(spot)}
          </>
        )}
      </div>
    </div>
  );
}
