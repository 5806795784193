import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faGear,
  faBars,
  faClose,
  faList,
  faStar,
  faStarHalfStroke,
  faSkullCrossbones,
  faEye,
  faEyeSlash,
  faSmile,
  faMeh,
  faSadCry,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./IconButton.module.css";
import { BasicStates } from "../../store/types";

type IconButtonProps = {
  hoverTag?: string;
  highlighted?: boolean;
  highlightMode?: BasicStates;
  type?: "Primary" | "Default";
  size?: "Small" | "Normal";
  icon:
    | "eye"
    | "eyeClosed"
    | "skull"
    | "gear"
    | "bars"
    | "close"
    | "list"
    | "smile"
    | "pf"
    | "sad"
    | "star"
    | "starStroke";
  handleClick: () => void;
};

export default function IconButton({
  hoverTag,
  highlighted,
  highlightMode,
  icon,
  size,
  type,
  handleClick,
}: IconButtonProps) {
  function resolveIcon(icon: string): IconDefinition {
    switch (icon) {
      case "gear":
        return faGear;
      case "bars":
        return faBars;
      case "close":
        return faClose;
      case "list":
        return faList;
      case "star":
        return faStar;
      case "starStroke":
        return faStarHalfStroke;
      case "skull":
        return faSkullCrossbones;
      case "eye":
        return faEye;
      case "eyeClosed":
        return faEyeSlash;
      case "smile":
        return faSmile;
      case "pf":
        return faMeh;
      case "sad":
        return faSadCry;
      default:
        return faGear;
    }
  }

  return (
    <button
      className={`${styles.IconButton} ${
        size !== undefined ? styles[size] : styles["Normal"]
      } ${
        highlighted !== undefined && highlighted === true
          ? styles.Highlighted
          : null
      } ${
        highlightMode !== undefined
          ? styles["Highlight" + highlightMode]
          : styles["HighlightPrimary"]
      }  ${type !== undefined ? styles[type] : styles["Primary"]}`}
      onClick={handleClick}
    >
      {hoverTag !== undefined && (
        <span>
          <em>{hoverTag}</em>
        </span>
      )}
      <FontAwesomeIcon icon={resolveIcon(icon)} />
    </button>
  );
}
