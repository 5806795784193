import { PartialSpot, Spot, StorageMaps } from "../store/types";

export const KEY_MAPS = "appKeysMaps";
export const LAST_OPENED_MAP = "appLastOpenedMap";

class UtilsClass {
  constructor() {}

  setLastOpenedMap(map: StorageMaps) {
    localStorage.setItem(LAST_OPENED_MAP, JSON.stringify(map));
  }

  getLastOpenedMap(): StorageMaps | null {
    const lastOpenedMapObject = localStorage.getItem(LAST_OPENED_MAP);
    if (lastOpenedMapObject === null) return null;
    const lastOpenedMap = JSON.parse(lastOpenedMapObject) as StorageMaps;
    return lastOpenedMap;
  }

  resolveMap(title: string): StorageMaps | null {
    const mapStorageObject = localStorage.getItem(KEY_MAPS);
    if (mapStorageObject === null) return null;
    const mapStorage = JSON.parse(mapStorageObject) as StorageMaps[];
    const map = mapStorage.filter((m) => m.title === title);
    if (map.length === 0) return null;
    return map[0];
  }

  updateMap(map: StorageMaps) {
    const mapStorageObject = localStorage.getItem(KEY_MAPS);
    if (mapStorageObject === null) throw "Error";
    const mapStorage = JSON.parse(mapStorageObject) as StorageMaps[];
    const newMapStorage = mapStorage.reduce((p: StorageMaps[], c) => {
      if (c.title === map.title) {
        p.push(map);
      } else {
        p.push(c);
      }
      return p;
    }, []);
    localStorage.setItem(KEY_MAPS, JSON.stringify(newMapStorage));
  }

  async updateSpot(title: string, spot: Spot) {
    const map = this.resolveMap(title);
    if (map === null) throw "Map not found";
    const newMapContent = map.content.reduce((p: Spot[], c) => {
      if (c.id === spot.id) {
        p.push(spot);
      } else {
        p.push(c);
      }
      return p;
    }, []);
    map.content = newMapContent;
    this.updateMap(map);
  }

  async deleteSpot(spot: Spot) {
    spot.hidden = true;
    const map = this.getLastOpenedMap();
    if (map === null) return;
    const newMapContent = map.content.reduce((p: Spot[], c) => {
      if (c.id === spot.id) {
        p.push(spot);
      } else {
        p.push(c);
      }
      return p;
    }, []);
    map.content = newMapContent;
    this.updateMap(map);
  }

  async createSpot(partialSpot: PartialSpot) {
    function generateId(spots: Spot[]): number {
      return (
        spots.reduce((p, c) => {
          p = c.id > p ? c.id : p;
          return p;
        }, 0) + 1
      );
    }
    const map = this.getLastOpenedMap();
    if (map === null) return;
    const newSpot: Spot = {
      id: generateId(map.content),
      lat: partialSpot.lat,
      lon: partialSpot.lon,
      regionId: 666,
      title: partialSpot.title,
    };
    map.content = [...map.content, newSpot];
    this.updateMap(map);
  }

  async resolveSpots(fileContent: Object) {
    return new Promise((resolve, reject) => {
      if (fileContent.hasOwnProperty("spots")) {
        const spots = (fileContent as any).spots;
        if (spots && spots.length > 0) {
          const resolvedSpots: Spot[] = spots.reduce((p: Spot[], c: any) => {
            if (
              c.lat === undefined ||
              c.lon === undefined ||
              c.id === undefined
            ) {
              return p;
            } else {
              p.push({
                id: c.id,
                lat: c.lat,
                lon: c.lon,
                regionId: c.regionId,
                title: c.title,
              });
              return p;
            }
          }, []);
          let savedMaps: StorageMaps[] = [];
          const storageKeyMaps = localStorage.getItem(KEY_MAPS);
          if (storageKeyMaps !== null) {
            const parsedStorageKeyMaps: StorageMaps[] =
              JSON.parse(storageKeyMaps);
            savedMaps = parsedStorageKeyMaps;
          }
          const newMap: StorageMaps = {
            title: `Map_${savedMaps.length + 1}`,
            content: resolvedSpots,
          };
          savedMaps = [...savedMaps, newMap];
          localStorage.setItem(KEY_MAPS, JSON.stringify(savedMaps));
          resolve(true);
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  }
}

export const Utils = Object.freeze(new UtilsClass());
