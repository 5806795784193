import { Spot } from "../../store/types";
import styles from "./AppMap.module.css";

type AppSpotListProps = {
  list: Spot[];
  handleClick: (spot: Spot) => void;
};

export default function AppSpotList({ list, handleClick }: AppSpotListProps) {
  return (
    <ul className={styles.AppSpotList}>
      {list.map((el, i) => {
        return (
          <li onClick={() => handleClick(el)} key={i}>
            {el.title}
          </li>
        );
      })}
    </ul>
  );
}
